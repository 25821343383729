<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Cart details") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 400px;"
      >
        <v-row class="pt-5">
          <v-col cols="12">
            <v-list v-for="(p, index) in item.meals"
                    :key="index" dense>
              <v-list-item dense>
                <v-list-item-avatar>
                  <v-avatar>
                    <v-img :src="p.meal.image || ''" />
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{p.meal.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{p.quantity}} x {{p.price}}F</v-list-item-subtitle>
                  <v-list-item-subtitle>{{p.total_price}}F</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  mdiClose,
  mdiPlus,
  mdiPencilOutline,
  mdiPencilOffOutline,
  mdiCalendar,
  mdiCloudUploadOutline,
} from '@mdi/js'
import {
  getIndex,
  getItem,
} from '@core/utils'
import {
  computed,
  ref,
  watch,
} from '@vue/composition-api'
import {
  required,
  emailValidator,
} from '@core/utils/validation'
import axios from '@axios'
import {
  format,
} from 'date-fns'
import {
  avatarText,
} from '@core/utils/filter'
import store from '@/store'
import controller from './Controller'

import AvatarName from '@/components/AvatarName'

import FileUpload from '@/components/file_upload'


export default {
  components: {
    FileUpload,
    AvatarName,
  },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, {
    emit,
  }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()

    const errorMessages = ref({})
    const valid = ref(false)
    const isFormtestActive = ref(false)
    const loading = ref(false)
    const tests = ref([])
    const isLoadingTests = ref(false)
    const search = ref(null)
    const minDate = new Date().toISOString().substr(0, 10)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    watch(item, val => {
      if (item.value.user && tests.value.findIndex(ele => ele.id === item.user_id) < 0) {
        // tests.value.push(item.value.user)
      }
    }, {
      deep: true,
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const removeTest = (index, test) => {
      item.value.meals.splice(index, 1)
      /*if (test.id != null) {
        axios.post(`/carts/action/delete_test/${item.value.id}`, { test_id: test.id })
      }*/
    }
    const addTest = () => {
      item.value.meals = item.value.meals || []
      item.value.meals.push({})
    }
    const formatDate = (date, f) => format(date, f)
    const newTest = () => {
      item.value.user = {
        name: search.value, email: null, tell: null,
      }
      isFormtestActive.value = true
    }

    const searchTests = term => {
      axios.get('/admin/tests', { params: { term, rowsPerPage: 100 } }).then(response => {
        isLoadingTests.value = false

        for (let i = 0; i < response.data.data.length; i++) {
          const u = response.data.data[i]
          const indexU = tests.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            tests.value.push(u)
          }
        }
      })
    }
    const updatetests = test => {
      isFormtestActive.value = false
      tests.value.push(test)
    }
    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        axios.post(`/admin/carts`, item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = {
                  ...response.data.errors,
                }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }

            item.value = response.data
            // emit('update:is-form-active', false)

            emit('cancel')
            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = {
                ...error.data.errors,
              }
            }
          })
      } else {
        validate()
      }
    }

    searchTests('')

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || val.trim().length < 3 || isLoadingTests.value) return ''
      isLoadingTests.value = true
      searchTests(val)

      return ''
    })

    const userIdChanged = computed({
      get: () => item.user_id,
    })

    watch(userIdChanged, val => {
      item.value.country_id = meals.value.find(i => i.id === val).country_id

      return ''
    })

    // onMounted(searchParticular)

    return {
      minDate,
      resetItem,
      form,
      isFormtestActive,
      search,
      tests,
      updatetests,
      newTest,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,

      item,
      valid,
      loading,
      validate,
      avatarText,
      addTest,
      removeTest,
      isLoadingTests,

      // validation
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPlus,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
